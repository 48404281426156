import React from "react";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { SwapWidget } from "../bridges/Swapbridge";
import { WidgetSwap } from "../bridges/Bridgeswap";
import { Button, Form, CardGroup} from "./../utils/Scripts";
import { FormGroup, FormControl, Spinner, Card, Container, Row, Col, Stack } from "react-bootstrap";
import UpgradeNear from './../superfluidFunctions/upgradeNear';
import DowngradeNear from './../superfluidFunctions/downgradeNear';
import createNewFlow from './../superfluidFunctions/createStream';
import updateExistingFlow from './../superfluidFunctions/updateStream';
import deleteFlow from './../superfluidFunctions/deleteStream';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import "./../SuperfluidStream.css";

const SuperfluidStream = () => {
    const [recipient, setRecipient] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [flowRate, setFlowRate] = useState("");
    const [flowRateDisplay, setFlowRateDisplay] = useState("");
    const [currentAccount, setCurrentAccount] = useState("");

    const connectWallet = async () => {
        const { ethereum } = window;
        if (!ethereum) {
            console.log("Ensure you have a MetaMask");
        }

        try {
            const accounts = await ethereum.request({
                method: "eth_requestAccounts",
            });
            setCurrentAccount(accounts[0]);
        } catch (error) {
            console.log("Error connecting to wallet: ", error);
        }
    };

    const checkIfWalletIsConnected = async () => {
        const { ethereum } = window;
        if (!ethereum) {
            console.log("Ensure you have a MetaMask");
        }
        const accounts = await ethereum.request({
            method: "eth_requestAccounts",
        });
        const chain = await ethereum.request({ method: "eth_chainId" });
        console.log("chain ID:", chain);

        console.log(accounts[0]);
        console.log('0x...');

        if (accounts.length > 0) {
            console.log("Found an authorized account: ", accounts[0]);
            setCurrentAccount(accounts[0]);
        } else {
            console.log("No authorized account found");
        }
    };

    useEffect(() => {
        checkIfWalletIsConnected();
    });

    function calculateFlowRate(amount) {
        if (
            typeof Number(amount) !== "number" ||
            isNaN(Number(amount)) === true
        ) {
            alert("You can only calculate a flowRate based on a number");
            return;
        } else if (typeof Number(amount) === "number") {
            if (Number(amount) === 0) {
                return 0;
            }
            const amountInWei = ethers.BigNumber.from(amount);
            const monthlyAmount = ethers.utils.formatEther(
                amountInWei.toString()
            );
            const calculatedFlowRate = monthlyAmount * 3600 * 24 * 30;
            return calculatedFlowRate;
        }
    }
    

    function CreateButton({ isLoading, children, ...props }) {
        return (
            <Button variant="success" className="button" {...props}>
                {isButtonLoading ? <Spinner animation="border" /> : children}
            </Button>
        );
    }

    function UpdateButton({ isLoading, children, ...props }) {
        return (
            <Button variant="success" className="button" {...props}>
                {isButtonLoading ? <Spinner animation="border" /> : children}
            </Button>
        );
    }

    function DeleteButton({ isLoading, children, ...props }) {
        return (
            <Button variant="success" className="button" {...props}>
                {isButtonLoading ? <Spinner animation="border" /> : children}
            </Button>
        );
    }

    const handleRecipientChange = (event) => {
        setRecipient(() => ([event.target.name] = event.target.value));
    };

    const handleFlowRateChange = (event) => {
        setFlowRate(() => ([event.target.name] = event.target.value));
        let newFlowRateDisplay = calculateFlowRate(event.target.value);
        setFlowRateDisplay(newFlowRateDisplay.toString());
    };

    return (
      <div>
       <div className="sfb__heather">
         <h1 className="gradient__text">BitcoinBAM Bridge</h1>
       </div>
       <div className="sfb__swap">
        <SwapWidget />
       </div>
    </div>
    );
};

export default SuperfluidStream;
