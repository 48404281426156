import { jsx as _jsx } from "react/jsx-runtime";
import { LiFiWidget } from '@lifi/widget';
const widgetConfig = {
    integrator: 'Bitcoinbam',
    fromChain: 1,
    fromToken: '0xd530f4D39fCd9143fad84f0815841c6DDD85F1cC',
    toChain: 56,
    toToken: '0xcf0990170A60DA34fFCffa14eAD4a3De27D0F4CE',
    containerStyle: {
        border: '1px solid rgb(234, 234, 234)',
        borderRadius: '16px',
    },
};
export const WidgetSwap = () => {
    return _jsx(LiFiWidget, { config: widgetConfig });
};
